import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary'

interface IErrorFallbackProps extends FallbackProps {}

const ErrorFallback = ({ error, resetErrorBoundary }: IErrorFallbackProps) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

// @ts-ignore *

const ErrorBoundary = ({ children }: any) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
