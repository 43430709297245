/* eslint-disable no-tabs */
import { createGlobalStyle } from 'styled-components'
import MoretTTF from '../fonts/Moret/Moret-Regular.ttf'
import MoretWOFF from '../fonts/Moret/Moret-Regular.woff'
import MoretWOFF2 from '../fonts/Moret/Moret-Regular.woff2'
import PlainWOFF2 from '../fonts/Plain/Plain-Light.woff2'
import PlainWOFF from '../fonts/Plain/Plain-Light.woff'
import PlainTTF from '../fonts/Plain/Plain-Light.ttf'

import { colors } from './colors'
import { breakpoints } from './breakpoints'
import { fonts } from './fonts'

export { colors, breakpoints, fonts }

const GlobalStyle = createGlobalStyle<{ width: number; height: number }>`

@font-face {
  font-family:'Plain';
  font-style: normal;
  font-display: auto;
  src:local('Plain'),
   url(${PlainWOFF}) format("woff"),
   url(${PlainTTF}) format("truetype"),
   url(${PlainWOFF2}) format("woff2");
}

@font-face {
  font-family:'Moret';
  font-style: normal;
  font-display: auto;
  src:local('Moret'),
   url(${MoretTTF}) format('ttf'),
   url(${MoretWOFF}) format('woff'),
   url(${MoretWOFF2}) format('woff2');
}

  p {
    color: #282750;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  :root {
    --vw: ${({ width }) => width}px;
    --vh: ${({ height }) => height}px;
  }

  body {
    margin: 0;
    padding: 0;
    width: inherit;
    min-height: ${({ height }) => height - 2}px;
    height: max-content;
    display: flex;
    flex-direction: column;
    font-family: 'Plain';
    overflow-x: hidden;

  input {
    font-family: 'Plain';
    outline: none !important;
    -webkit-appearance: none;
    padding: 0;
    background-color: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:focus {
    outline: none;
  }

  //For chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
//For Firefox
input[type=number]{
    -moz-appearance: textfield;
}

  button {
    font-family: 'Plain';
    border: none;
    background: ${colors.greenAccent};
  }

  a {
      font-family: 'Plain';
      display: block;
      color: ${colors.darkBlue};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: ${colors.darkBlue};
        text-decoration: none;
      }
    }
  }
`
export default GlobalStyle
