import styled from 'styled-components/macro'

export const FooterWrapper = styled.div<{ withText: boolean }>`
  background: linear-gradient(1deg, #ffffff 19.86%, rgba(255, 255, 255, 0) 94.05%);
  margin-top: ${({ withText }) => (withText ? '160px' : '0')};
  padding: 0 70px 40px 70px;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    margin-top: 92px;
    padding: 0 21px 29px 21px;

    > div > svg {
      width: 154px;
    }
  }
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 19px;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    flex-direction: column;
    margin-top: 25px;
  }
`

export const FooterText = styled.p`
  font-size: 14px;
  max-width: 814px;
  text-align: center;
  margin: 0 auto 93px auto;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 300;
  line-height: 130%;
  letter-spacing: -0.01em;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    font-size: 14px;
    text-align: center;
    margin: 0 auto 60px auto;
  }
`

export const FooterInfoBox = styled.div`
  @media (${({ theme }) => theme.breakpoints.m9}) {
    order: 2;
    margin-top: 25px;
    flex-direction: column;
  }
`

export const FooterInfoText = styled.div`
  display: flex;
  gap: 28px;
  line-height: 130%;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.gray};

  @media (${({ theme }) => theme.breakpoints.m9}) {
    justify-content: space-between;
    font-size: 16px;
  }
`

export const FooterLinksBox = styled.div`
  display: flex;
  gap: 47px;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    flex-direction: column;
    gap: 15px;
  }
`

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 47px;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    flex-direction: column;
    justify-content: center;
    gap: 7px;
  }
`

export const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.darkBlue};
  cursor: pointer;
  font-size: 14px;
  line-height: 125%;
  margin-bottom: 5px;
  letter-spacing: -0.02em;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    font-weight: 300;
    font-size: 16px;
  }
`

export const FooterLine = styled.div(({ theme }) => ({
  height: 1,
  width: '100%',
  background: theme.colors.gray,
  marginTop: 23
}))

export const FooterBottomText = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 23px;
  text-align: right;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.02em;

  @media (${({ theme }) => theme.breakpoints.m9}) {
    font-size: 16px;
    line-height: 123.4%;
  }
`

export const ContactUsInfo = styled.div`
  @media (${({ theme }) => theme.breakpoints.m9}) {
    margin: 15px 0px;
  }
`
