import { createAsyncThunk } from '@reduxjs/toolkit'
import { IReferral } from '@services/referrals/interface'
import { createReferral, deleteReferral, getAllReferrals, uploadReferrals } from '@services/referrals/service'

export const createReferralThunk = createAsyncThunk(
  'referrals/create',
  async (data: IReferral, { rejectWithValue }) => {
    try {
      return await createReferral(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const getAllReferralsThunk = createAsyncThunk('referrals/all', async () => getAllReferrals())

export const deleteReferralThunk = createAsyncThunk('referrals/delete', async (id: string) =>
  deleteReferral(id)
)

export const uploadReferralsThunk = createAsyncThunk(
  'referrals/upload',
  async (data: FormData, { rejectWithValue }) => {
    try {
      return await uploadReferrals(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
