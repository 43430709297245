import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserModel } from '@interfaces/models'
import { IsAuthenticatedEnum, Token } from '@constants/requests'
import { removeToken, setToken } from '@helpers/token'
import { RootState } from '@store'
import {
  getProfileInfoThunk,
  loginThunk,
  logoutThunk,
  // updateProfileInfoThunk,
  resetPasswordThunk
} from './thunks'

export interface AuthState {
  isAuthenticated: IsAuthenticatedEnum
  user: UserModel | null
  errors: any
  resetData: boolean
}

const initialState: AuthState = {
  isAuthenticated: IsAuthenticatedEnum.GUEST,
  user: null,
  errors: null,
  resetData: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload
    },
    resetStore: (state) => {
      state.isAuthenticated = IsAuthenticatedEnum.GUEST
    },
    setResetData: (state) => {
      state.resetData = false
    },
    clearForm: (state) => {
      state.errors = []
      state.resetData = false
    }
  },
  extraReducers: (builder) => {
    /** LOGIN * */
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      setToken(payload.accessToken)
      setToken(payload.refreshToken, Token.REFRESH)
      state.isAuthenticated = IsAuthenticatedEnum.AUTH
    })
    builder.addCase(loginThunk.rejected, (state, { payload }) => {
      state.errors = payload
      state.isAuthenticated = IsAuthenticatedEnum.GUEST
    })

    /** LOGOUT * */
    builder.addCase(logoutThunk.fulfilled, (state) => {
      removeToken(Token.ACCESS)
      removeToken(Token.REFRESH)
      state.isAuthenticated = IsAuthenticatedEnum.GUEST
      state.user = null
    })
    builder.addCase(logoutThunk.rejected, (state) => {
      removeToken(Token.ACCESS)
      removeToken(Token.REFRESH)
      state.isAuthenticated = IsAuthenticatedEnum.GUEST
      state.user = null
    })

    /** GET_PROFILE_INFO * */
    builder.addCase(getProfileInfoThunk.fulfilled, (state, action) => {
      state.user = action.payload
      state.isAuthenticated = IsAuthenticatedEnum.AUTH
    })

    builder.addCase(getProfileInfoThunk.rejected, (state, action) => {
      state.isAuthenticated = IsAuthenticatedEnum.GUEST
      removeToken(Token.ACCESS)
      removeToken(Token.REFRESH)
    })

    // builder.addCase(updateProfileInfoThunk.rejected, (state, action) => {
    //   state.errors = action.payload
    // })
    /** UPDATE_PASSWORD * */
    builder.addCase(resetPasswordThunk.fulfilled, (state, action) => {
      state.resetData = true
    })
    builder.addCase(resetPasswordThunk.rejected, (state, action) => {
      state.errors = action.payload
    })
  }
})

export const isAuthenticated = (state: RootState) => state.auth.isAuthenticated
export const getUser = (state: RootState) => state.auth.user
export const getResetData = (state: RootState) => state.auth.resetData
export const getErrors = (state: RootState) => state.auth.errors?.details

export const { setAuthenticated, resetStore, clearForm, setResetData } = authSlice.actions

export default authSlice.reducer
