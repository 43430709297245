import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store'

interface IInitialState {
  hamburgerMenu: boolean
}

const initialState: IInitialState = {
  hamburgerMenu: false
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    toggleMobileModalStatus(state, { payload }) {
      state.hamburgerMenu = payload
    }
  }
})

export const hamburgerMenuStatus = (state: RootState) => state.general.hamburgerMenu
export const { toggleMobileModalStatus } = generalSlice.actions

export default generalSlice.reducer
