import { Token } from '@constants/requests'

export const getToken = (key = Token.ACCESS) => {
  return localStorage.getItem(key)
}

export const removeToken = (key = Token.ACCESS) => {
  localStorage.removeItem(key)
}

export const setToken = (value: string, key = Token.ACCESS) => {
  localStorage.setItem(key, value)
}
