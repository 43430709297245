import React, { useEffect } from 'react'
import { useAppDispatch } from '@store/hooks'
import { sizes } from '@assets/GlobalStyle/breakpoints'
import { toggleMobileModalStatus } from '@store/slices/general'
import { ReactComponent as MobileCloseIcon } from '@assets/icons/mobile-close-icon.svg'
import { StyledHamburgerMenu, Header, Body, HamburgerMenuCloseContainer } from './styled'

const HeaderHamburgerMenu = ({ status, children }: { status: boolean; children: React.ReactNode }) => {
  const dispatch = useAppDispatch()

  const handelToggle = () => {
    if (window.innerWidth > sizes.m9) {
      dispatch(toggleMobileModalStatus(false))
      window.removeEventListener('resize', handelToggle)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handelToggle)
  }, [status])

  return (
    <StyledHamburgerMenu status={status ? 'open' : 'close'}>
      <Header>
        <HamburgerMenuCloseContainer
          onClick={() => dispatch(toggleMobileModalStatus(false))}
        >
          <MobileCloseIcon />
        </HamburgerMenuCloseContainer>
      </Header>
      <Body>
        {children}
      </Body>
    </StyledHamburgerMenu>
  )
}

export default HeaderHamburgerMenu
