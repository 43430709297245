import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import authReducer from './slices/auth'
import generalReducer from './slices/general'
import referralsReducer from './slices/referrals'

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    general: generalReducer,
    auth: authReducer,
    referrals: referralsReducer
  }
})
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
