import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReferralsModel } from '@interfaces/models'
import { RootState } from '@store'
import { createReferralThunk, deleteReferralThunk, getAllReferralsThunk } from './thunks'

export interface ReferralsState {
  allReferrals: ReferralsModel[]
}
const initialState: ReferralsState = {
  allReferrals: []
}

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllReferralsThunk.fulfilled, (state, action: PayloadAction<any>) => {
      state.allReferrals = action.payload.data
    })
    builder.addCase(createReferralThunk.fulfilled, () => {
      alert('Referral created successfully')
    })
    builder.addCase(deleteReferralThunk.fulfilled, () => {
      alert('Referral deleted successfully')
    })
  }
})

export const getAllReferrals = (state: RootState) => state.referrals.allReferrals
export default referralsSlice.reducer
