import { AMAZON_S3_CDN_URL } from '@configs'

export const getImagePath = (path: string) => `${AMAZON_S3_CDN_URL}/${path}`

export enum Token {
  ACCESS = 'ACCESS',
  REFRESH = 'REFRESH'
}

export enum IsAuthenticatedEnum {
  AUTH = 'AUTH',
  GUEST = 'GUEST'
}

export enum ErrorMessages {
  INVALID_USER = 'INVALID_USER',
  INVALID_TOKEN = 'INVALID_TOKEN',
  ACCESS_DENIED = 'ACCESS_DENIED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  SERVER_ERROR = 'SERVER_ERROR',
  TOKEN_EXPIRED_ERROR = 'TokenExpiredError',
  JSON_WEB_TOKEN_ERROR = 'JsonWebTokenError',

  PASSWORD_REQUIRED = 'Password is required',
  CURRENT_PASS_REQUIRED = 'Current Password is required',
  NEW_PASS_REQUIRED = 'New Password is required',
  CONFIRM_PASS_REQUIRED = 'Confirm Password is required',
  PASSWORDS_MATCH = 'Confirm Password is required'
}

export const FileTypes = ['jpeg', 'jpg', 'png', 'webp']

export const getFileTypeErrorMessage = (type: string) =>
  `Invalid type ${type} , Allowed only ${FileTypes.join(',')} extension`
