'use client'

import { useEffect } from 'react'

import { useNavigate, useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ThemeProvider } from 'styled-components'
import GlobalStyles from '@components/GlobalStyles'
import Theme from '@assets/GlobalStyle/Theme'
import { getProfileInfoThunk } from '@store/slices/auth/thunks'
import { IsAuthenticatedEnum } from '@constants/requests'
import { isAuthenticated, setAuthenticated } from '@store/slices/auth'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import routes from './router'
import { init } from './api/axios'
import { getToken } from './helpers/token'

function App() {
  init()
  const content = useRoutes(routes)
  const dispatch = useAppDispatch()
  const auth = useAppSelector(isAuthenticated)
  const navigate = useNavigate()
  const token = getToken()

  // if (token && auth === IsAuthenticated.AUTH))
  if (token) {
    setTimeout(() => dispatch(setAuthenticated(IsAuthenticatedEnum.AUTH)), 1000)
  }

  // useEffect(() => {
  //   if (!token) {
  //     navigate('/')
  //   }
  // }, [])

  useEffect(() => {
    if (auth === IsAuthenticatedEnum.AUTH) {
      dispatch(getProfileInfoThunk())
    }
  }, [auth])

  return (
    <>
      <ThemeProvider theme={Theme}>
        <ToastContainer />
        <GlobalStyles />
        {content}
      </ThemeProvider>
    </>
  )
}

export default App
