export const sizes = {
  w14: 1440,
  w13: 1366,
  w12: 1280,
  w11: 1175,
  m9: 992,
  m6: 600
}
export type BreakpointsType = { [width in keyof typeof sizes]: string }

export const breakpoints = Object.fromEntries(
  Object.entries(sizes).map(([key, value]) => [key, `max-width: ${value}px`])
) as BreakpointsType
