import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { useAppSelector } from '@store/hooks'
import { isAuthenticated } from '@store/slices/auth'
import { hamburgerMenuStatus } from '@store/slices/general'
import { IsAuthenticatedEnum, Token } from '@constants/requests'
import Header from '@components/header/MainHeader'
import MainFooter from '@components/footer/MainFooter'
import Loader from '@components/Loader'
import { StyledLayout, StyledMain } from './styled'

const PrivateLayout: FC = () => {
  const auth = useAppSelector(isAuthenticated)
  const hamburgerMenu = useAppSelector(hamburgerMenuStatus)
  const navigate = useNavigate()
  const token = localStorage.getItem(Token.ACCESS)

  useEffect(() => {
    if (auth === IsAuthenticatedEnum.GUEST && !token) {
      navigate('/')
    }
  }, [auth])

  return (
    <StyledLayout contentStatus={hamburgerMenu ? 'static' : 'dynamic'}>
      <StyledMain>
        <Header />
        {auth === IsAuthenticatedEnum.AUTH ? <Outlet /> : <Loader />}
      </StyledMain>
      <MainFooter />
    </StyledLayout>
  )
}
export default PrivateLayout
