import { PAGE_ROUTES_PRIVATE } from '@constants/pages'
import { useNavigate } from 'react-router-dom'

const Logo = () => {
  const navigate = useNavigate()
  const handleClick = (e: any) => {
    e.preventDefault()
  }

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      <svg width='207' height='22' viewBox='0 0 207 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6942_6391)'>
          <path d='M0 21.6764C0.210152 21.0293 0.525381 20.0588 0.735533 18.9803C0.945685 17.9019 1.15584 16.8235 1.26091 15.6372L2.41675 5.93131C2.52183 4.74503 2.6269 3.6666 2.6269 2.58817C2.6269 1.50974 2.6269 0.646992 2.52183 -0.10791H7.77563L10.1924 8.4117C10.1924 8.62738 10.1924 8.73523 10.2975 8.95091C10.2975 9.1666 10.4025 9.27444 10.4025 9.49013C10.6127 10.0293 10.7178 10.4607 10.8228 10.9999C10.9279 11.5391 11.033 12.0784 11.1381 12.6176H11.3482C11.5584 11.9705 11.7685 11.4313 11.9787 10.8921C12.1888 10.3529 12.5041 9.81366 12.7142 9.38229C12.7142 9.1666 12.8193 9.05876 12.9244 8.95091C13.0294 8.84307 13.0294 8.73523 13.1345 8.51954L17.5477 -6.7018e-05H22.8015C22.4863 0.754835 22.2761 1.61758 22.066 2.69601C21.8558 3.77444 21.6457 4.85287 21.5406 6.03915L20.3848 15.745C20.2797 16.9313 20.1746 18.0097 20.1746 19.0882C20.1746 20.1666 20.1746 21.0293 20.2797 21.7842H15.3411C15.5513 21.0293 15.8665 20.1666 16.0767 19.0882C16.2868 18.0097 16.497 16.9313 16.602 15.8529L17.6528 6.90189H17.4426L11.1381 19.3039H9.77208L6.30457 6.68621H6.09442L5.04366 15.6372C4.93858 16.8235 4.8335 17.9019 4.8335 18.9803C4.8335 20.0588 4.8335 20.9215 4.93858 21.6764H0Z' fill='#282750' />
          <path d='M29.0007 21.9997C27.6347 21.9997 26.3738 21.6762 25.4281 21.0291C24.4824 20.3821 23.7469 19.4115 23.3266 18.2252C22.9063 17.0389 22.8012 15.5291 23.0113 13.9115C23.2215 12.2938 23.6418 10.784 24.3773 9.59775C25.1128 8.41148 26.0585 7.44089 27.1093 6.79383C28.2651 6.14677 29.526 5.82324 30.9971 5.82324C32.4682 5.82324 33.624 6.14677 34.6748 6.79383C35.6205 7.44089 36.356 8.41148 36.7763 9.59775C37.1966 10.784 37.3017 12.2938 37.0915 13.9115C36.8814 15.5291 36.4611 16.9311 35.7255 18.1174C34.99 19.3036 34.0443 20.2742 32.8885 21.0291C31.7326 21.6762 30.3667 21.9997 29.0007 21.9997ZM29.2108 19.8428C30.0514 19.8428 30.787 19.6272 31.4174 19.0879C32.0479 18.6566 32.6783 17.9017 33.0986 17.0389C33.5189 16.1762 33.8342 15.0978 33.9392 13.9115C34.0443 12.7252 34.0443 11.6468 33.8342 10.6762C33.624 9.81344 33.2037 9.16638 32.6783 8.62716C32.153 8.19579 31.4174 7.87226 30.6819 7.87226C29.8413 7.87226 29.1057 8.08795 28.4753 8.62716C27.8448 9.05854 27.3194 9.81344 26.7941 10.6762C26.3738 11.5389 26.0585 12.6174 25.9535 13.9115C25.8484 15.0978 25.8484 16.1762 26.0585 17.0389C26.2687 17.9017 26.689 18.6566 27.2144 19.0879C27.7398 19.6272 28.3702 19.8428 29.2108 19.8428Z' fill='#282750' />
          <path d='M52.0127 21.6764C52.2228 21.0293 52.5381 20.0588 52.7482 18.9803C52.9584 17.9019 53.1685 16.8235 53.2736 15.6372L54.4294 5.93131C54.5345 4.74503 54.6396 3.6666 54.6396 2.58817C54.6396 1.50974 54.6396 0.646992 54.5345 -0.10791H59.7883L62.2051 8.4117C62.2051 8.62738 62.2051 8.73523 62.3102 8.95091C62.3102 9.1666 62.4152 9.27444 62.4152 9.49013C62.6254 10.0293 62.7305 10.4607 62.8355 10.9999C62.9406 11.5391 63.0457 12.0784 63.1508 12.6176H63.3609C63.5711 11.9705 63.7812 11.4313 63.9914 10.8921C64.2015 10.3529 64.5168 9.81366 64.7269 9.38229C64.7269 9.1666 64.832 9.05876 64.9371 8.95091C65.0421 8.84307 65.0421 8.73523 65.1472 8.51954L69.5604 -6.7018e-05H74.8142C74.499 0.754835 74.2888 1.61758 74.0787 2.69601C73.8685 3.77444 73.6584 4.85287 73.5533 6.03915L72.3975 15.745C72.2924 16.9313 72.1873 18.0097 72.1873 19.0882C72.1873 20.1666 72.1873 21.0293 72.2924 21.7842H67.3538C67.564 21.0293 67.8792 20.1666 68.0893 19.0882C68.2995 18.0097 68.5097 16.9313 68.6147 15.8529L69.6655 6.90189H69.3503L63.0457 19.3039H61.7848L58.3173 6.68621H58.1071L57.0563 15.6372C56.9513 16.8235 56.8462 17.9019 56.8462 18.9803C56.8462 20.0588 56.8462 20.9215 56.9513 21.6764H52.0127Z' fill='#282750' />
          <path d='M81.5388 21.9997C80.1728 21.9997 78.9118 21.6762 77.9662 21.0291C77.0205 20.3821 76.2849 19.4115 75.8646 18.2252C75.4443 17.0389 75.3393 15.5291 75.5494 13.9115C75.7596 12.2938 76.1799 10.784 76.9154 9.59775C77.6509 8.41148 78.5966 7.44089 79.6474 6.79383C80.8032 6.14677 82.0641 5.82324 83.5352 5.82324C85.0063 5.82324 86.1621 6.14677 87.2129 6.79383C88.2636 7.44089 88.8941 8.41148 89.3144 9.59775C89.7347 10.784 89.8398 12.2938 89.6296 13.9115C89.4195 15.5291 88.9992 16.9311 88.2636 18.1174C87.5281 19.3036 86.5824 20.2742 85.4266 21.0291C84.2707 21.6762 83.0098 21.9997 81.5388 21.9997ZM81.854 19.0879C82.5895 19.0879 83.22 18.8723 83.7453 18.4409C84.3758 18.0095 84.7961 17.3625 85.2164 16.6076C85.6367 15.8527 85.8469 14.8821 85.952 13.9115C86.057 12.833 86.057 11.8625 85.8469 11.1076C85.6367 10.3527 85.3215 9.7056 84.7961 9.27422C84.2707 8.84285 83.7454 8.62716 83.0098 8.62716C82.2743 8.62716 81.6438 8.84285 81.1184 9.27422C80.5931 9.7056 80.0677 10.3527 79.6474 11.1076C79.2271 11.8625 79.0169 12.833 78.9118 13.9115C78.8068 14.9899 78.8068 15.8527 79.0169 16.6076C79.2271 17.3625 79.5423 18.0095 80.0677 18.4409C80.5931 18.8723 81.2235 19.0879 81.854 19.0879Z' fill='#282750' />
          <path d='M93.833 21.6763C93.938 21.137 93.938 20.49 93.938 19.7351C93.938 18.9802 93.833 18.2253 93.7279 17.3625C93.7279 17.3625 93.7279 17.3625 93.7279 17.2547C93.7279 17.2547 93.7279 17.2547 93.7279 17.1468L92.3619 10.6762C92.2568 9.70566 92.0467 8.84292 91.8365 8.08802C91.6264 7.33311 91.4162 6.57821 91.2061 6.14684H95.6192C95.6192 6.68605 95.6192 7.22527 95.6192 7.87233C95.6192 8.51939 95.7243 9.16645 95.8294 9.92135L96.9852 15.637C96.9852 15.8527 97.0903 16.0684 97.0903 16.2841C97.0903 16.4998 97.1954 16.7155 97.1954 16.9312C97.1954 17.1468 97.1954 17.3625 97.1954 17.4704C97.1954 17.6861 97.1954 17.7939 97.1954 18.0096H97.5106C97.6157 17.9017 97.6157 17.6861 97.7208 17.4704C97.7208 17.2547 97.8258 17.039 97.9309 16.9312C98.036 16.7155 98.1411 16.3919 98.1411 16.1763C98.2462 15.9606 98.2462 15.7449 98.3512 15.5292L100.873 9.81351C101.188 9.0586 101.398 8.41154 101.504 7.76449C101.609 7.11743 101.819 6.57821 101.924 5.93115H106.337C106.022 6.47037 105.601 7.11743 105.286 7.98017C104.866 8.73507 104.551 9.59782 104.235 10.5684L101.398 17.039C101.398 17.039 101.398 17.039 101.398 17.1468C101.398 17.1468 101.398 17.1468 101.293 17.2547C100.978 18.0096 100.768 18.7645 100.558 19.5194C100.348 20.2743 100.243 20.9213 100.138 21.4606H93.833V21.6763Z' fill='#282750' />
          <path d='M112.956 21.9998C111.485 21.9998 110.224 21.6763 109.069 21.0292C108.018 20.3821 107.177 19.4115 106.652 18.2253C106.126 17.039 106.021 15.5292 106.126 13.9115C106.337 12.2939 106.757 10.8919 107.492 9.70566C108.228 8.51939 109.174 7.5488 110.33 6.90174C111.485 6.25468 112.746 5.93115 114.217 5.93115C115.583 5.93115 116.739 6.14684 117.58 6.57821C118.525 7.00958 119.156 7.65664 119.471 8.51939C119.891 9.38213 119.997 10.3527 119.891 11.4312C119.786 12.4017 119.471 13.2645 119.051 13.9115C118.525 14.5586 117.895 15.0978 117.054 15.5292C116.214 15.8527 115.268 16.0684 114.217 16.0684C113.272 16.0684 112.431 15.9606 111.59 15.637C110.855 15.3135 110.119 14.99 109.594 14.4508L109.384 14.5586C109.279 15.4213 109.489 16.2841 109.909 16.9312C110.33 17.6861 110.855 18.2253 111.59 18.5488C112.326 18.9802 113.061 19.088 114.007 19.088C114.953 19.088 115.793 18.9802 116.634 18.6566C117.475 18.441 118.105 18.0096 118.736 17.4704L118.525 20.5978C117.79 21.0292 116.949 21.3527 116.004 21.5684C115.058 21.8919 114.007 21.9998 112.956 21.9998ZM113.272 14.0194C114.112 14.0194 114.848 13.8037 115.478 13.2645C116.109 12.8331 116.424 12.1861 116.529 11.3233C116.634 10.6763 116.529 10.2449 116.319 9.81351C116.109 9.38213 115.793 9.0586 115.373 8.84292C114.953 8.62723 114.427 8.51939 113.797 8.51939C113.272 8.51939 112.641 8.62723 112.116 8.95076C111.59 9.27429 111.065 9.70566 110.75 10.3527C110.435 10.9998 110.014 11.6468 109.909 12.4017C110.33 12.8331 110.75 13.2645 111.275 13.4802C111.801 13.8037 112.536 14.0194 113.272 14.0194Z' fill='#282750' />
          <path d='M125.671 21.9998C124.83 21.9998 123.884 21.8919 122.728 21.6763C121.678 21.4606 120.732 21.137 120.102 20.9213L120.522 17.3625C121.257 17.9017 122.098 18.2253 123.044 18.5488C123.989 18.8723 124.935 18.9802 125.776 18.9802C126.616 18.9802 127.352 18.8723 127.877 18.5488C128.403 18.2253 128.718 17.7939 128.718 17.2547C128.718 16.8233 128.718 16.4998 128.403 16.1763C128.192 15.8527 127.877 15.637 127.457 15.4213C127.037 15.2057 126.511 14.99 125.881 14.6664C124.935 14.3429 124.094 14.0194 123.464 13.6959C122.834 13.3723 122.308 12.941 121.888 12.4017C121.468 11.8625 121.362 11.1076 121.468 10.2449C121.573 9.38213 121.888 8.62723 122.413 7.98017C122.939 7.33311 123.674 6.7939 124.62 6.47037C125.566 6.14684 126.616 5.93115 127.772 5.93115C128.613 5.93115 129.453 6.039 130.189 6.14684C131.029 6.36252 131.66 6.57821 132.29 6.90174L131.975 10.2449C131.45 9.92135 130.819 9.59782 130.084 9.38213C129.348 9.16645 128.508 9.0586 127.667 9.0586C126.932 9.0586 126.196 9.16645 125.776 9.48998C125.25 9.81351 125.04 10.137 124.935 10.6763C124.935 10.9998 124.935 11.3233 125.25 11.6468C125.46 11.8625 125.776 12.1861 126.196 12.4017C126.616 12.6174 127.142 12.8331 127.667 13.0488C128.613 13.3723 129.453 13.8037 130.084 14.1272C130.714 14.4508 131.24 14.8821 131.66 15.5292C132.08 16.1763 132.185 16.9312 132.08 17.7939C131.975 18.6566 131.66 19.5194 131.029 20.1664C130.504 20.8135 129.663 21.4606 128.823 21.7841C127.982 21.8919 126.826 21.9998 125.671 21.9998Z' fill='#282750' />
          <path d='M136.493 21.6765C136.704 21.0295 136.914 20.3824 137.124 19.5197C137.334 18.6569 137.439 17.7942 137.544 16.8236L137.965 13.5883L134.917 6.03928C134.497 4.85301 134.077 3.77458 133.656 2.69615C133.236 1.61771 132.816 0.754969 132.396 0.10791H137.439C137.544 0.647126 137.649 1.40203 137.754 2.15693C137.965 2.91183 138.175 3.77458 138.385 4.63732L140.171 8.8432H140.486L143.323 4.63732C143.744 3.77458 144.164 3.01967 144.479 2.26477C144.794 1.50987 145.11 0.754969 145.32 0.10791H150.364C149.838 0.862812 149.208 1.72556 148.472 2.80399C147.737 3.88242 147.106 4.96085 146.476 6.14713L141.747 13.6961L141.327 17.0393C141.222 18.0099 141.117 18.8726 141.117 19.6275C141.117 20.3824 141.117 21.1373 141.222 21.7844H136.493V21.6765Z' fill='#282750' />
          <path d='M152.466 21.9997C151.1 21.9997 149.839 21.6762 148.893 21.0291C147.947 20.3821 147.212 19.4115 146.791 18.2252C146.371 17.0389 146.266 15.5291 146.476 13.9115C146.686 12.2938 147.107 10.784 147.842 9.59775C148.578 8.41148 149.523 7.44089 150.574 6.79383C151.73 6.14677 152.991 5.82324 154.462 5.82324C155.933 5.82324 157.089 6.14677 158.14 6.79383C159.085 7.44089 159.821 8.41148 160.241 9.59775C160.661 10.784 160.767 12.2938 160.556 13.9115C160.346 15.5291 159.926 16.9311 159.19 18.1174C158.455 19.3036 157.509 20.2742 156.353 21.0291C155.197 21.6762 153.832 21.9997 152.466 21.9997ZM152.781 19.0879C153.516 19.0879 154.147 18.8723 154.672 18.4409C155.303 18.0095 155.723 17.3625 156.143 16.6076C156.563 15.8527 156.774 14.8821 156.879 13.9115C156.984 12.833 156.984 11.8625 156.774 11.1076C156.563 10.3527 156.248 9.7056 155.723 9.27422C155.197 8.84285 154.672 8.62716 153.937 8.62716C153.201 8.62716 152.571 8.84285 152.045 9.27422C151.52 9.7056 150.994 10.3527 150.574 11.1076C150.154 11.8625 149.944 12.833 149.839 13.9115C149.734 14.9899 149.734 15.8527 149.944 16.6076C150.154 17.3625 150.469 18.0095 150.994 18.4409C151.52 18.8723 152.045 19.0879 152.781 19.0879Z' fill='#282750' />
          <path d='M167.386 21.9998C166.335 21.9998 165.389 21.7842 164.654 21.2449C163.918 20.7057 163.393 20.0587 163.078 19.0881C162.762 18.1175 162.762 17.0391 162.868 15.5293L163.393 11.3234C163.498 10.2449 163.603 9.27436 163.603 8.30377C163.603 7.44102 163.603 6.68612 163.498 6.03906H168.226C168.016 6.68612 167.806 7.44102 167.596 8.30377C167.386 9.16651 167.281 10.0293 167.071 11.1077L166.65 14.6665C166.545 15.9606 166.65 16.9312 167.071 17.5783C167.491 18.2253 168.226 18.5489 169.277 18.5489C169.908 18.5489 170.538 18.3332 171.063 17.9018C171.589 17.4704 172.114 16.9312 172.535 16.2842C172.955 15.6371 173.165 14.8822 173.27 14.1273L173.585 10.9998C173.69 10.1371 173.795 9.16651 173.795 8.19593C173.795 7.22534 173.795 6.47044 173.69 6.03906H178.419C178.104 6.79396 177.893 7.54887 177.788 8.30377C177.683 9.05867 177.473 9.92142 177.368 10.9998L176.738 16.392C176.633 17.4704 176.527 18.3332 176.527 19.1959C176.527 20.0587 176.527 20.8136 176.633 21.4606H171.904V21.3528C172.114 20.9214 172.324 20.49 172.429 20.0587C172.535 19.6273 172.64 19.0881 172.745 18.441L172.85 17.9018H172.535C172.219 18.7646 171.799 19.4116 171.274 19.9508C170.748 20.49 170.223 20.9214 169.487 21.2449C168.857 21.892 168.121 21.9998 167.386 21.9998Z' fill='#282750' />
          <path d='M179.47 21.6764C179.155 21.6764 178.945 21.5685 178.735 21.3529C178.525 21.1372 178.42 20.9215 178.525 20.598C178.525 20.2744 178.735 20.0587 178.945 19.8431C179.155 19.6274 179.47 19.5195 179.786 19.5195C180.101 19.5195 180.311 19.6274 180.521 19.8431C180.731 20.0587 180.836 20.3823 180.731 20.598C180.731 20.9215 180.521 21.1372 180.311 21.3529C179.996 21.5685 179.786 21.6764 179.47 21.6764Z' fill='#282750' />
          <path d='M184.935 21.6766C184.199 21.6766 183.463 21.5688 183.043 21.2452C182.518 20.9217 182.203 20.4903 181.992 19.9511C181.782 19.4119 181.782 18.7648 181.782 18.0099C181.887 17.255 182.097 16.608 182.413 16.0688C182.728 15.5295 183.148 15.0982 183.779 14.7746C184.304 14.4511 185.04 14.3433 185.775 14.3433C186.09 14.3433 186.406 14.3433 186.826 14.4511C187.246 14.5589 187.561 14.6668 187.666 14.7746L187.351 16.608C187.141 16.5001 186.931 16.3923 186.721 16.2844C186.406 16.1766 186.195 16.1766 185.88 16.1766C185.46 16.1766 185.145 16.2844 184.935 16.3923C184.724 16.5001 184.409 16.7158 184.304 17.0393C184.094 17.3629 183.989 17.6864 183.989 18.1178C183.989 18.5491 183.989 18.8727 184.094 19.1962C184.199 19.5197 184.409 19.7354 184.619 19.8433C184.829 19.9511 185.145 20.0589 185.565 20.0589C185.88 20.0589 186.195 20.0589 186.511 19.9511C186.826 19.8433 187.141 19.7354 187.351 19.5197L187.141 21.4609C186.931 21.5688 186.511 21.6766 186.09 21.7844C185.67 21.6766 185.25 21.6766 184.935 21.6766Z' fill='#282750' />
          <path d='M190.819 21.6766C190.083 21.6766 189.453 21.5688 189.032 21.2452C188.612 20.9217 188.192 20.4903 187.982 19.9511C187.771 19.4119 187.771 18.7648 187.771 18.0099C187.877 17.255 188.087 16.608 188.402 16.0688C188.717 15.5295 189.137 15.0982 189.663 14.7746C190.188 14.4511 190.819 14.3433 191.554 14.3433C192.29 14.3433 192.92 14.4511 193.341 14.7746C193.761 15.0982 194.181 15.5295 194.286 16.0688C194.496 16.608 194.496 17.255 194.391 18.0099C194.286 18.7648 194.076 19.4119 193.761 19.9511C193.446 20.4903 193.025 20.9217 192.5 21.2452C192.185 21.5688 191.554 21.6766 190.819 21.6766ZM191.029 19.9511C191.239 19.9511 191.554 19.8433 191.764 19.7354C191.975 19.6276 192.185 19.304 192.29 18.9805C192.395 18.657 192.5 18.3335 192.605 17.9021C192.605 17.4707 192.605 17.1472 192.605 16.8237C192.5 16.5001 192.395 16.2844 192.29 16.0688C192.185 15.8531 191.869 15.8531 191.659 15.8531C191.344 15.8531 191.134 15.9609 190.924 16.0688C190.714 16.1766 190.503 16.5001 190.398 16.8237C190.293 17.1472 190.188 17.4707 190.083 17.9021C190.083 18.3335 190.083 18.657 190.083 18.9805C190.188 19.304 190.293 19.5197 190.398 19.7354C190.609 19.9511 190.819 19.9511 191.029 19.9511Z' fill='#282750' />
          <path d='M195.021 21.4608C195.127 21.0294 195.337 20.598 195.442 20.1667C195.547 19.7353 195.652 19.1961 195.652 18.7647L195.757 17.3627C195.862 16.8235 195.862 16.2843 195.862 15.7451C195.862 15.2059 195.862 14.7745 195.757 14.3431H198.594C198.489 14.5588 198.384 14.7745 198.279 14.9902C198.174 15.2059 198.174 15.5294 198.069 15.8529V15.9608H198.174C198.384 15.3137 198.699 14.8823 199.119 14.5588C199.54 14.2353 199.96 14.1274 200.485 14.1274C201.011 14.1274 201.431 14.2353 201.746 14.5588C202.062 14.8823 202.272 15.3137 202.377 15.9608H202.482C202.587 15.5294 202.797 15.2059 203.112 14.9902C203.322 14.6667 203.638 14.5588 203.953 14.3431C204.268 14.2353 204.583 14.1274 205.004 14.1274C205.739 14.1274 206.265 14.3431 206.58 14.8823C206.895 15.4216 207 16.1765 206.895 17.147L206.79 18.3333C206.685 18.8725 206.685 19.4118 206.685 19.951C206.685 20.4902 206.685 20.9216 206.79 21.3529H203.953C204.058 20.9216 204.163 20.4902 204.373 20.0588C204.583 19.6274 204.583 19.0882 204.583 18.549L204.688 17.5784C204.794 17.0392 204.688 16.6078 204.583 16.3921C204.478 16.0686 204.163 15.9608 203.848 15.9608C203.638 15.9608 203.428 16.0686 203.217 16.1765C203.007 16.2843 202.902 16.5 202.692 16.8235C202.587 17.0392 202.482 17.3627 202.377 17.6863L202.272 18.549C202.167 19.0882 202.167 19.6274 202.167 20.0588C202.167 20.598 202.167 21.0294 202.272 21.3529H199.435C199.54 21.0294 199.645 20.598 199.75 20.1667C199.855 19.7353 199.96 19.1961 199.96 18.549L200.065 17.5784C200.17 17.0392 200.065 16.6078 199.96 16.3921C199.855 16.0686 199.54 15.9608 199.225 15.9608C199.014 15.9608 198.804 16.0686 198.594 16.1765C198.384 16.2843 198.279 16.5 198.174 16.8235C198.069 17.0392 197.964 17.3627 197.964 17.6863L197.859 18.6569C197.753 19.0882 197.753 19.6274 197.753 20.1667C197.753 20.7059 197.753 21.1372 197.859 21.4608H195.021Z' fill='#282750' />
          <path d='M34.7628 12.2193C35.2359 10.2534 34.4777 8.37022 33.0693 8.01316C31.6608 7.6561 30.1355 8.96033 29.6624 10.9262C29.1892 12.8922 29.9474 14.7753 31.3559 15.1324C32.7643 15.4894 34.2896 14.1852 34.7628 12.2193Z' fill='#282750' />
          <path d='M42.7663 22.0002C41.4003 22.0002 40.1394 21.6767 39.1937 21.0296C38.248 20.3826 37.5125 19.412 37.0922 18.2257C36.6719 17.0394 36.5668 15.5296 36.777 13.912C36.9871 12.2943 37.4074 10.7845 38.1429 9.59824C38.8785 8.41197 39.8242 7.44138 40.8749 6.79432C42.0308 6.14726 43.2917 5.82373 44.7627 5.82373C46.2338 5.82373 47.3896 6.14726 48.4404 6.79432C49.4912 7.44138 50.1216 8.41197 50.5419 9.59824C50.9622 10.7845 51.0673 12.2943 50.8572 13.912C50.647 15.5296 50.2267 16.9316 49.4912 18.1178C48.7556 19.3041 47.8099 20.2747 46.6541 21.0296C45.4983 21.6767 44.2374 22.0002 42.7663 22.0002ZM43.0815 19.8433C43.9221 19.8433 44.6577 19.6277 45.2881 19.0884C45.9186 18.6571 46.549 17.9022 46.9693 17.0394C47.3896 16.1767 47.7049 15.0982 47.8099 13.912C47.915 12.7257 47.915 11.6473 47.7049 10.6767C47.4947 9.81393 47.0744 9.05902 46.549 8.62765C46.0237 8.19628 45.2881 7.87275 44.5526 7.87275C43.712 7.87275 42.9764 8.08844 42.346 8.62765C41.7155 9.05902 41.1902 9.81393 40.6648 10.6767C40.2445 11.5394 39.9292 12.6178 39.8242 13.912C39.614 15.0982 39.614 16.1767 39.9292 17.0394C40.1394 17.9022 40.5597 18.6571 41.0851 19.0884C41.6105 19.6277 42.2409 19.8433 43.0815 19.8433Z' fill='#282750' />
          <path d='M48.6094 12.1895C49.0826 10.2236 48.3244 8.34044 46.916 7.98337C45.5075 7.62631 43.9822 8.93054 43.5091 10.8965C43.0359 12.8624 43.7941 14.7455 45.2025 15.1026C46.611 15.4596 48.1363 14.1554 48.6094 12.1895Z' fill='#282750' />
        </g>
        <defs>
          <clipPath id='clip0_6942_6391'>
            <rect width='207' height='22' fill='white' />
          </clipPath>
        </defs>
      </svg>

    </div>
  )
}

export default Logo
