import styled from 'styled-components'

export const HamburgerMenuButton = styled.div`
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  width: 51.96px;
  height: 49.97px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  
  '&:hover': {
    background: ${({ theme }) => theme.colors.white};
  }

  @media(${({ theme }) => theme.breakpoints.m9}) {
    display: flex;
  }
`
