import styled from 'styled-components/macro'

export const StyledLayout = styled.div<{ contentStatus: 'static' | 'dynamic' }>`
  min-height: 100vh;
  height: ${({ contentStatus }) => (contentStatus === 'static' ? '100vh' : 'unset')};
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.beigeBackground};
`
export const StyledMain = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 70px;

  @media (${({ theme }) => theme.breakpoints.w12}) {
    padding: 30px 40px;
  }

  @media (${({ theme }) => theme.breakpoints.m9}) {
    padding: 20px 10px;
  }
`
