import axios from 'axios'

import { toast } from 'react-toastify'
import { API_URL } from '@configs'
import { getToken, removeToken } from '@helpers/token'
import { ErrorMessages, Token } from '@constants/requests'

class Exception extends Error {
  private readonly details: any | null

  constructor(name: string, details: any = null) {
    super(name)
    Object.setPrototypeOf(this, new.target.prototype)
    this.details = details
  }
}

interface IParams {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  url: string
  data?: any
  params?: any
}

export const client = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: API_URL
})

export const clientFormData = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  baseURL: API_URL
})

export const clientS3 = axios.create({
  headers: {
    'Content-Type': 'application/octet-stream'
  },
  withCredentials: false
})

export const init = () => {
  const jwtToken = getToken()
  if (jwtToken) {
    client.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
    clientFormData.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  }
}

export const sendRequest = async (params: IParams) => {
  try {
    const result = await client.request({ ...params })

    if (params.method !== 'GET') {
      toast('SUCCESS', { type: 'success', autoClose: 1500 })
    }

    return result.data
  } catch (err: any) {
    const { details, error } = err.response.data
    if (
      [
        ErrorMessages.UNAUTHORIZED,
        ErrorMessages.JSON_WEB_TOKEN_ERROR,
        ErrorMessages.TOKEN_EXPIRED_ERROR
      ].includes(error)
    ) {
      removeToken()
      removeToken(Token.REFRESH)
    }
    throw new Exception(error, details)
  }
}

export const sendRequestFormData = async (params: IParams) => {
  try {
    const result = await clientFormData.request({ ...params })

    if (params.method !== 'GET') {
      toast('SUCCESS', { type: 'success', autoClose: 1500 })
    }

    return result.data
  } catch (err: any) {
    const { details, error } = err.response.data
    if (
      [
        ErrorMessages.UNAUTHORIZED,
        ErrorMessages.JSON_WEB_TOKEN_ERROR,
        ErrorMessages.TOKEN_EXPIRED_ERROR
      ].includes(error)
    ) {
      removeToken()
      removeToken(Token.REFRESH)
    }
    throw new Exception(error, details)
  }
}

export const axiosInstance = client
