import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProfileInfo,
  login,
  logout,
  // updateProfileInfo,
  resetPassword,
  setNewPassword
} from '@services/auth/service'
import {
  ILogin,
  IUpdateProfileInfo,
  IResetPassword,
  ISetNewPasswordPayload
} from '@services/auth/interface'

export const loginThunk = createAsyncThunk('auth', async (data: ILogin, { rejectWithValue }) => {
  try {
    return await login(data)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const logoutThunk = createAsyncThunk('auth/logout', async () => logout())

export const getProfileInfoThunk = createAsyncThunk('auth/profile', async () => getProfileInfo())

// export const updateProfileInfoThunk = createAsyncThunk(
//   'auth/profile/update',
//   async (data: IUpdateProfileInfo, { rejectWithValue, dispatch }) => {
//     try {
//       await updateProfileInfo(data)
//       dispatch(getProfileInfoThunk())
//     } catch (err) {
//       return rejectWithValue(err)
//     }
//   }
// )

export const resetPasswordThunk = createAsyncThunk(
  'auth/password/update',
  async (data: IResetPassword, { rejectWithValue }) => {
    try {
      return await resetPassword(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const setNewPasswordThunk = createAsyncThunk(
  'auth/password/update',
  async (data: ISetNewPasswordPayload, { rejectWithValue }) => {
    try {
      return await setNewPassword(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
