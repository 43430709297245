const colors = {
  darkBlue: '#282750',
  beigeBackground: '#FEF6EB',
  pinkBackground: '#F8E6EB',
  greenAccent: '#A6E6A9',
  orangeAccent: '#FFB49E',
  gray: '#A09D95',
  green: '#82B5A5',
  yellow: '#FFCF47',
  red: '#F36B7F',
  lightBlue: '#A5B8F5',
  white: '#FFFFFF',
  black: '#000000'
}

export { colors }

export type ColorsType = typeof colors
