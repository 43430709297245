import exp from 'constants'
import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(${({ theme }) => theme.breakpoints.m9}) {
    > div:nth-of-type(1) > svg {
      display: none;
    }
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;

  @media(${({ theme }) => theme.breakpoints.m9}) {
    display: none;
  }
`

export const ProfileName = styled.span`
  ${({ theme }) => theme.fonts.Plain};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 10px;
`

export const LogoutSection = styled(ProfileName)`
  margin-left: 30px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    opacity: 0.8;
  }

  @media(${({ theme }) => theme.breakpoints.m9}) {
    margin-left: 0;
  }
`

export const MenuContentWrapper = styled.div`
  padding: 20px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;  

   @media(${({ theme }) => theme.breakpoints.m9}) {
    & > div {
      display: flex;
      align-items: center;
    }

    > div, > span  {
      color: rgb(40, 39, 80);
      cursor: pointer;
      border-bottom: 1px solid rgb(160, 157, 149);
      padding: 20px 0px;
      width: 100%;
      font-size: 14px;
    }
  }
`
