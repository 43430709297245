import { URL } from '@configs'

export enum PAGE_ROUTES_PUBLIC {
  LOG_IN = '/',
  FORGOT_PASSWORD = '/reset-password',
  SET_NEW_PASSWORD = '/set-new-password',
  PASSWORD_CHANGED = '/password-changed'
}

export enum PAGE_ROUTES_PRIVATE {
  PORTAL = '/portal'
}

export const REDIRECT_TO = {
  COOKIES_POLICY: `${URL}/cookies-policy`,
  ABOUT: `${URL}/about`,
  MAIN_SITE: `${URL}/`,
  SIGN_UP: `${URL}/affiliate-become-partner`,
  FAQ: `${URL}/faq`,
  AFFILIATE: `${URL}/affiliate`
}
