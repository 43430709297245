import { sendRequest } from '@api/axios'
import { ILogin, IUpdateProfileInfo, IResetPassword, ISetNewPasswordPayload } from './interface'

const prefix = '/auth'

export const login = async (data: ILogin) => {
  return sendRequest({
    method: 'POST',
    url: `${prefix}/login`,
    data
  })
}

export const logout = async () => {
  return sendRequest({
    method: 'POST',
    url: `${prefix}/signOut`
  })
}

export const getProfileInfo = async () => {
  return sendRequest({
    method: 'GET',
    url: '/profile'
  })
}

export const resetPassword = async (data: IResetPassword) => {
  return sendRequest({
    method: 'POST',
    url: `${prefix}/resetPassword`,
    data
  })
}

export const setNewPassword = async (data: ISetNewPasswordPayload) => {
  return sendRequest({
    method: 'PUT',
    url: `${prefix}/setPassword`,
    data
  })
}
