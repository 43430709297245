import styled from 'styled-components'

export const StyledHamburgerMenu = styled.div<{ status: 'open' | 'close' }>`
  position: fixed;
  top: 0;
  left: ${({ status }) => status === 'open' ? '0%' : '100%'};
  width: 100vw;
  background: white;
  min-height: 100vh;
  transition: left .5s;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`

export const Body = styled.div`
`

export const HamburgerMenuCloseContainer = styled.div`
  display: none;

  @media(${({ theme }) => theme.breakpoints.m9}) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 51.96px;
    height: 49.97px;

    background: #fff;
    border-radius: unset;
    border: 1px solid #000;

    '&:hover': {
      background: #fff;
    }
  }
`
