import { Property } from 'csstype'

interface IFont {
  'font-family': Property.FontFamily
  'font-weight': Property.FontWeight
  'font-style': Property.FontStyle
  'line-height': any
  'letter-spacing'?: string
}

export interface IFonts {
  Moret: IFont
  Plain: IFont
}

const Fonts: IFonts = {
  Moret: {
    'font-family': 'Moret',
    'font-weight': 400,
    'font-style': 'normal',
    'line-height': '116%'
  },

  Plain: {
    'font-family': 'Plain',
    'font-weight': 300,
    'font-style': 'normal',
    'line-height': '130%',
    'letter-spacing': ' -0.02em'
  }
}

export type FontsType = Record<keyof IFonts, string>

export const fonts = Object.fromEntries(
  Object.entries(Fonts).map(([key, value]) => [
    key,
    Object.entries(value)
      .map(([fontKey, fontValue]) => `${fontKey}: ${fontValue};`)
      .join('\n')
  ])
) as FontsType
