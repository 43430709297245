import profileSvg from '@assets/icons/profile.svg'
import { getUser } from '@store/slices/auth'
import { logoutThunk } from '@store/slices/auth/thunks'
import { toggleMobileModalStatus } from '@store/slices/general'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import Logo from '@components/Logo'
import HamburgerMenu from '@components/HamburgerMenu'
import { Container, LogoutSection, Profile, ProfileName, MenuContentWrapper } from './styled'

const Header = () => {
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()

  const logout = () => {
    dispatch(logoutThunk())
    dispatch(toggleMobileModalStatus(false))
  }

  return (
    <Container>
      <Logo />
      <Profile>
        <img src={profileSvg} alt='profile' />
        <ProfileName>{user?.username}</ProfileName>
        <LogoutSection onClick={logout}>Log Out</LogoutSection>
      </Profile>
      <HamburgerMenu>
        <MenuContentWrapper>
          <div>
            <img src={profileSvg} alt='profile' />
            <ProfileName>{user?.username}</ProfileName>
          </div>
          <LogoutSection onClick={logout}>Log Out</LogoutSection>
        </MenuContentWrapper>
      </HamburgerMenu>
    </Container>
  )
}

export default Header
