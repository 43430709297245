import { useNavigate } from 'react-router-dom'
import Logo from '@components/Logo'
import { REDIRECT_TO } from '@constants/pages'
import {
  FooterWrapper,
  FooterText,
  FooterContent,
  FooterInfoBox,
  FooterInfoText,
  FooterLinksBox,
  FooterLink,
  FooterLinks,
  FooterLine,
  FooterBottomText,
  ContactUsInfo
} from './styled'

// eslint-disable-next-line react/require-default-props
const MainFooter = ({ withText = true }: { withText?: boolean }) => {
  const navigate = useNavigate()
  return (
    <FooterWrapper withText={withText}>
      {withText ?
        <FooterText>
          Moo Moves You is the first Digital Moving Marketplace. Our mission is to simplify and
          standardize the moving process by combining our easy-to-use technology
          with our nationwide network of moving companies to provide
          you with a hassle-free moving experience.
        </FooterText>
        : null}

      <Logo />

      <FooterContent>
        <FooterInfoBox>
          <FooterInfoText>
            <div>
              Moo Moves You, LLC
              <br />
              3500 NW 2nd Ave.
              <br />
              Suite 623
              <br />
              Boca Raton FL 33431
            </div>
            <div>
              MC #: 1430500
              <br />
              DOT #: 3891017
            </div>
          </FooterInfoText>
        </FooterInfoBox>

        <FooterLinksBox>
          <FooterLinks>
            <FooterLink href={REDIRECT_TO.ABOUT}>About</FooterLink>
            <FooterLink onClick={() => navigate('')}>How MOO works</FooterLink>
            <FooterLink onClick={() => navigate('')}>FAQs</FooterLink>
            <FooterLink onClick={() => navigate('')}>Trucker partner</FooterLink>
            <FooterLink>Contact Us</FooterLink>
          </FooterLinks>
          <ContactUsInfo>
            <FooterLink href='tel:786-535-5979'>(786) 535-5979</FooterLink>
            <FooterLink href='mailto:support@moomovesyou.com'>support@moomovesyou.com</FooterLink>
          </ContactUsInfo>

          <div>
            <FooterLink
              onClick={() => navigate('')}
            >
              Terms of Use
            </FooterLink>
            <FooterLink href={REDIRECT_TO.COOKIES_POLICY}>Privacy Policy</FooterLink>
            <FooterLink href={REDIRECT_TO.COOKIES_POLICY}>Cookie Policy</FooterLink>
          </div>

        </FooterLinksBox>
      </FooterContent>

      <FooterLine />

      <FooterBottomText>(C) 2023 Moo Moves You</FooterBottomText>
    </FooterWrapper>
  )
}
export default MainFooter
