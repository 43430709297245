import { memo } from 'react'
import useWindowSize from '@hooks/useWindowSize'
import GlobalStyle from '@assets/GlobalStyle'

const GlobalStyles = () => {
  const { width, height } = useWindowSize()

  return <GlobalStyle width={width} height={height} />
}

export default memo(GlobalStyles)
