import styled from 'styled-components'

export const LayoutContainer = styled.div<{ contentStatus: 'static' | 'dynamic' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: ${({ contentStatus }) => contentStatus === 'static' ? '100vh' : 'unset'};
  overflow: ${({ contentStatus }) => contentStatus === 'static' ? 'hidden' : 'unset'};
`
