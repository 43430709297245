import { FC, Suspense, lazy } from 'react'

import PrivateLayout from '@layout/Private'
import PublicLayout from '@layout/Public'

import { RouteObject } from 'react-router'
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from './constants/pages'

const Loader = (Component: FC) => (props: any) => {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Component {...props} />
    </Suspense>
  )
}

const SignIn = Loader(lazy(() => import('@pages/login')))
const ReferralsPortal = Loader(lazy(() => import('@pages/portal')))
const ResetPassword = Loader(lazy(() => import('@pages/resetPassword/EmailPart')))
const NewPassword = Loader(lazy(() => import('@pages/resetPassword/NewPasswordPart')))
const PasswordChanged = Loader(lazy(() => import('@pages/resetPassword/ChangedPart')))

const routes: RouteObject[] = [
  {
    path: PAGE_ROUTES_PUBLIC.LOG_IN,
    element: <PublicLayout />,
    children: [
      {
        path: '',
        element: <SignIn />
      },
      {
        path: PAGE_ROUTES_PUBLIC.FORGOT_PASSWORD,
        element: <ResetPassword />
      },
      {
        path: PAGE_ROUTES_PUBLIC.SET_NEW_PASSWORD,
        element: <NewPassword />
      },
      {
        path: PAGE_ROUTES_PUBLIC.PASSWORD_CHANGED,
        element: <PasswordChanged />
      }
    ]
  },
  {
    path: PAGE_ROUTES_PRIVATE.PORTAL,
    element: <PrivateLayout />,
    children: [
      {
        path: '',
        element: <ReferralsPortal />
      }
    ]
  }
]

export default routes
