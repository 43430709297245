import { useAppDispatch, useAppSelector } from '@store/hooks'
import { hamburgerMenuStatus, toggleMobileModalStatus } from '@store/slices/general'
import HeaderHamburgerMenu from '@components/common/HeaderHamburgerMenu'
import { ReactComponent as MobileBurgerMenuIcon } from '@assets/icons/mobile-burger-menu-icon.svg'
import { HamburgerMenuButton } from './styled'

const HamburgerMenu = ({ children }: { children: React.ReactNode }) => {
  const hamburgerMenu = useAppSelector(hamburgerMenuStatus)
  const dispatch = useAppDispatch()

  return (
    <>
      <HamburgerMenuButton onClick={() => dispatch(toggleMobileModalStatus(true))}>
        <MobileBurgerMenuIcon />
      </HamburgerMenuButton>
      <HeaderHamburgerMenu status={hamburgerMenu}>
        {children}
      </HeaderHamburgerMenu>
    </>
  )
}

export default HamburgerMenu
