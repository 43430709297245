import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAppSelector } from '@store/hooks'
import { hamburgerMenuStatus } from '@store/slices/general'
import { isAuthenticated } from '@store/slices/auth'
import { IsAuthenticatedEnum } from '@constants/requests'
import { PAGE_ROUTES_PRIVATE } from '@constants/pages'
import { LayoutContainer } from './styled'

const PublicLayout = () => {
  const isAuth = useAppSelector(isAuthenticated)
  const hamburgerMenu = useAppSelector(hamburgerMenuStatus)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuth === IsAuthenticatedEnum.AUTH) {
      navigate(PAGE_ROUTES_PRIVATE.PORTAL)
    }
  }, [isAuth])

  return (
    <LayoutContainer contentStatus={hamburgerMenu ? 'static' : 'dynamic'}>
      <Outlet />
    </LayoutContainer>
  )
}
export default PublicLayout
