import { sendRequest, sendRequestFormData } from '@api/axios'
import { IReferral } from './interface'

export const createReferral = async (data: IReferral) => {
  return sendRequest({
    method: 'POST',
    url: 'users/invite',
    data
  })
}

export const getAllReferrals = async () => {
  return sendRequest({
    method: 'GET',
    url: '/referrals'
  })
}

export const deleteReferral = async (id: string) => {
  return sendRequest({
    method: 'DELETE',
    url: `users/referrals/${id}`
  })
}

export const uploadReferrals = async (data: FormData) => {
  return sendRequestFormData({
    method: 'POST',
    url: 'referral-partners/invite',
    data
  })
}
